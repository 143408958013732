<template>
  <a-card class="card" :bordered="false">
    <base-page
      :queryFields="queryFields"
      :columns="columns"
      :rules="rules"
      :modelTitle="modelTitle"
      :formFields="formFields"
      :moduleName="moduleName"
      :getPageFun="getWmsDataPage"
      :deleteFun="deleteWmsDataInfo"
      :createFun="createWmsDataInfo"
      :updateFun="updateWmsDataInfo">
    </base-page>
  </a-card>
</template>
<script>
import { BasePage } from '@/components'
import { getWmsDataPage, createWmsDataInfo, updateWmsDataInfo, deleteWmsDataInfo } from '@/api/wms'
export default {
  components: {
    BasePage
  },
  data() {
    return {
      queryFields: [
        {
          label: '进仓编号', name: 'in_warehouse_num', fieldType: '', queryType: '%'
        },
        {
          label: '车牌号', name: 'plate_number', fieldType: '', queryType: '%'
        }
      ],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 30
        },
        {
          title: '进仓编号',
          dataIndex: 'in_warehouse_num'
        },
        {
          title: '车牌号',
          dataIndex: 'plate_number'
        },
        {
          title: '提示内容',
          dataIndex: 'content'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      rules: {
        in_warehouse_num: [
          { required: true, message: '请输入进仓编号', trigger: 'blur' }
        ]
      },
      modelTitle: '进仓提示',
      formFields: [
        {
          label: '进仓编号', name: 'in_warehouse_num', type: 'default'
        },
        {
          label: '车牌号', name: 'plate_number', type: 'default'
        },
        {
          label: '提示内容', name: 'content', type: 'textarea', span: 24
        }
      ],
      moduleName: 'enter_tips'
    }
  },
  methods: {
    getWmsDataPage,
    createWmsDataInfo,
    updateWmsDataInfo,
    deleteWmsDataInfo
  }
}
</script>
